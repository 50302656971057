import React, { useState, useEffect ,useRef ,useMemo } from 'react';
import { Line } from 'react-chartjs-2'; // react-chartjs-2からLineをimport
import PropTypes from 'prop-types'; // 追加
import axios from "axios";
import Title from './Title';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import Button from '@mui/material/Button';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import CircularProgress from '@mui/material/CircularProgress';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

// 必要な数の半角スペースを追加する関数
function addSpaces(currentLength, maxLength) {
  let spacesNeeded = parseInt(maxLength) - parseInt(currentLength);
  if(spacesNeeded > 0){
    spacesNeeded = spacesNeeded + spacesNeeded;
  }
  return '\u00A0'.repeat(spacesNeeded);
}

const fetchDataFromServer = async (company_id,privilegeFlg) => {
  try {
    
    const info_flg = '0';
    if(!privilegeFlg){
      privilegeFlg = '0';
    }

    const requestData = {
      path: '/get_info',
      body: JSON.stringify({
        company_id: company_id,
        info_flg: info_flg,
        privilege_flg:privilegeFlg,
        chart_flg:'1',
      }),
    };

    const response = await axios.post(apiEndpoint, requestData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response && response.data && response.data.statusCode === 200) {
      return response.data.body; // レスポンスのデータを返す
    } else {
      alert('Error in API response:', response.data);
      return null;
    }
  } catch (error) {
    console.error('Error fetching data from server:', error);
    return null;
  }
};

const convertTimeTo30Minutes = (timeString) => {
  // 時間を ":" で分割して配列に格納
  const timeParts = timeString.split(':');

  // 分を取得し、整数に変換
  let hour = parseInt(timeParts[0]);
  let minutes = parseInt(timeParts[1]);
  let seconds = parseInt(timeParts[2]);

  // 分が 30 分未満の場合、30 分に切り上げ
  if (minutes > 0 && minutes < 30) {
    minutes = '00';
  }else if(minutes === 0 && seconds === 1){
    minutes = '00';
  }else if(minutes === 30 && seconds === 0){
    minutes = '00';
  }else if(minutes === 0 && seconds === 0){
    hour = hour - 1 === -1 ? 23 : hour - 1;
    minutes = '30';
  }else {
    // 分が 30 分以上の場合、30 分に切り捨て
    minutes = '30';
  }

  // ":" を使って時間と分を結合して新しい時刻文字列を作成
  const convertedTime = `${hour}:${minutes}`;

  return convertedTime;
};

const convertTimeToRestMinutes = (timeString) => {
  // 時間を ":" で分割して配列に格納
  const timeParts = timeString.split(':');

  // 時、分、秒を整数に変換
  const minutes = parseInt(timeParts[1]);
  const seconds = parseInt(timeParts[2]);

  // 00秒の場合は00秒のままでいい
  const restSeconds = seconds === 0 ? '00' : (60 - seconds).toString().padStart(2, '0');

  // 指定された時刻を基準として、次の30分の時刻を求める
  let restMinutes;
  if ((minutes === 30 || minutes === 0) && seconds === 0) {
    restMinutes = 0;
  }else if (minutes < 30) {
    restMinutes = 30 - minutes;
  } else {
    restMinutes = 60 - minutes;
  }

  // 秒が0でない場合のみ、分から1引く
  if (seconds !== 0) {
    restMinutes -= 1;
  }

  // 時刻の差分を求める
  const convertedTime = `${restMinutes.toString().padStart(2, '0')}:${restSeconds}`;
  return convertedTime;
};

export default function Chart({meter_id,company_id,privilegeFlg,onMeterSelect,setButtonText}) {
  const [chartData, setChartData] = useState({
    nowPowerData: [],
    nowGoalPowerData: [],
    goalPowerData: [],
    contractPowerData: [],
  });
  const chartRef = useRef(null);
  const [meter_list, setMeterList] = React.useState([]);
  const [selectedMeterId, setSelectedMeterId] = React.useState(null);
  const [now_power, setNowPower] = React.useState(null);
  const [now_goal_power, setNowGoalPower] = React.useState(null);
  const [goal_power, setGoalPower] = React.useState(null);
  const [contract_power,setContractPower]= React.useState(null);
  const [month_max_power,setMonthMaxPower]= React.useState(null);
  const [get_date,setGetDate]= React.useState(null);
  const [get_time,setGetTime]= React.useState(null);
  const [get_time_limit,setGetTimeLimit]= React.useState(null);
  const [rest_time,setRestTime]= React.useState(null);
  const [icon, setIcon] = useState(<TagFacesIcon sx={{ fontSize: '90px' }} />);
  const buttonProcessing = useRef(false);
  const memoizedSelectedMeterId = useMemo(() => selectedMeterId, [selectedMeterId]);
  const memoizedNowPower = useMemo(() => now_power, [now_power]);
  const memoizedNowGoalPower = useMemo(() => now_goal_power, [now_goal_power]);
  const memoizedGoalPower = useMemo(() => goal_power, [goal_power]);
  const memoizedContractPower = useMemo(() => contract_power, [contract_power]);
  const memoizedGetDate = useMemo(() => get_date, [get_date]);
  const memoizedGetTime = useMemo(() => get_time, [get_time]);
  const memoizedGetTimeLimit = useMemo(() => get_time_limit, [get_time_limit]);
  const memoizedMonthMaxPower = useMemo(() => month_max_power, [month_max_power]);
  const [isLoading, setIsLoading] = useState(true); // 読み込み中の状態を管理
  const [coefficient,setCoefficient] = useState(0);
  const [coefficient_magnification,setCoefficientMagnification] = useState(0);
  const [unit_of_demand_power,setUnitOfDemandPower] = useState(0);
  
  let prev_aws_get_dt = '';
  let non_communication_flg = false;

  const inputStyle = {
    height: '40px',
    width: '60%',
    fontSize: '20px',
  };

  // handleChangeStatus関数の定義
  const handleChangeStatus = async (status) => {
    let newIcon = <TagFacesIcon sx={{ fontSize: '90px' }} />;
    if (status == 2) {
      newIcon = <MoodBadIcon sx={{ fontSize: '90px', color: 'red' }} />;
    }else if (status == 1){
      newIcon = <SentimentVeryDissatisfiedIcon sx={{ fontSize: '90px', color: 'yellow' }} />;
    }
    setIcon(newIcon);
  };


  const handlePrevClick = async () => {
    console.log(buttonProcessing.current);
    if (buttonProcessing.current) return; // 送信処理中の場合は処理を中断する
    buttonProcessing.current = true; // 送信処理中フラグを立てる
    
    const currentIndex = meter_list.findIndex((meter) => meter.METER_ID === selectedMeterId);
  
    // 現在の選択が最初の選択でない場合
    if (currentIndex > 0) {

      const newSelectedContractPower = meter_list[currentIndex - 1].CONTRACT_POWER;
      if(!newSelectedContractPower){
        alert("契約電力が未設定です。設定してください")
        setButtonText("ホーム");
        return;
      }
      setContractPower(newSelectedContractPower);

      const newSelectedGoalPower = meter_list[currentIndex - 1].GOAL_POWER;
      if(!newSelectedGoalPower){
        alert("目標電力が未設定です。設定してください")
        setButtonText("ホーム");
        return;
      }

      setGoalPower(newSelectedGoalPower);

      const newSelectedCoefficient = meter_list[currentIndex - 1].COEFFICIENT ? meter_list[currentIndex - 1].COEFFICIENT : null;
      setCoefficient(newSelectedCoefficient);

      const newSelectedCoefficientMagnification = meter_list[currentIndex - 1].COEFFICIENT_MAGNIFICATION ?meter_list[currentIndex - 1].COEFFICIENT_MAGNIFICATION : null;
      setCoefficientMagnification(newSelectedCoefficientMagnification);

      const newSelectedUnitOfDemandPower = meter_list[currentIndex - 1].UNIT_OF_DEMAND_POWER;
      const unitOfDemandPower = newSelectedUnitOfDemandPower ? parseFloat(newSelectedUnitOfDemandPower.match(/[\d.]+/)) : null;
      setUnitOfDemandPower(unitOfDemandPower);

      //handleChangeStatus(meter_list[currentIndex - 1].STATUS);

      const newSelectedMeterId = meter_list[currentIndex - 1].METER_ID;

      setSelectedMeterId(newSelectedMeterId);
      onMeterSelect(newSelectedMeterId);
      
    }
    
    buttonProcessing.current = false;
    
  };

  const handleNextClick = async () => {
    console.log(buttonProcessing.current);
    if (buttonProcessing.current) return; // 送信処理中の場合は処理を中断する
    buttonProcessing.current = true; // 送信処理中フラグを立てる

    const currentIndex = meter_list.findIndex((meter) => meter.METER_ID === selectedMeterId);
  
    // 現在の選択が最初の選択でない場合
    if (currentIndex < meter_list.length - 1) {
      

      const newSelectedContractPower = meter_list[currentIndex + 1].CONTRACT_POWER;
      if(!newSelectedContractPower){
        alert("契約電力が未設定です。設定してください")
        setButtonText("ホーム");
        return;
      }
      setContractPower(newSelectedContractPower);
      
      const newSelectedGoalPower = meter_list[currentIndex + 1].GOAL_POWER;
      if(!newSelectedGoalPower){
        alert("目標電力が未設定です。設定してください")
        setButtonText("ホーム");
        return;
      }

      setGoalPower(newSelectedGoalPower);

      const newSelectedCoefficient = meter_list[currentIndex + 1].COEFFICIENT ? meter_list[currentIndex + 1].COEFFICIENT : null;
      setCoefficient(newSelectedCoefficient);

      const newSelectedCoefficientMagnification = meter_list[currentIndex + 1].COEFFICIENT_MAGNIFICATION ? meter_list[currentIndex + 1].COEFFICIENT_MAGNIFICATION : null;
      setCoefficientMagnification(newSelectedCoefficientMagnification);

      const newSelectedUnitOfDemandPower = meter_list[currentIndex + 1].UNIT_OF_DEMAND_POWER;
      const unitOfDemandPower = newSelectedUnitOfDemandPower ? parseFloat(newSelectedUnitOfDemandPower.match(/[\d.]+/)) : null;
      setUnitOfDemandPower(unitOfDemandPower);

      //handleChangeStatus(meter_list[currentIndex + 1].STATUS);

      const newSelectedMeterId = meter_list[currentIndex + 1].METER_ID;

      setSelectedMeterId(newSelectedMeterId);
      onMeterSelect(newSelectedMeterId);

      buttonProcessing.current = false;
    }
  
  };

  const handleChangeMeterId = (event) => { 
    
    const newSelectedMeterId = meter_list[event.target.selectedIndex].METER_ID;
    const currentIndex = meter_list.findIndex((meter) => meter.METER_ID === newSelectedMeterId);

    const newSelectedContractPower = meter_list[currentIndex].CONTRACT_POWER;
    if(!newSelectedContractPower){
      alert("契約電力が未設定です。設定してください")
      setButtonText("ホーム");
      return;
    }
    setContractPower(newSelectedContractPower);

    const newSelectedGoalPower = meter_list[currentIndex].GOAL_POWER;
    if(!newSelectedGoalPower){
      alert("目標電力が未設定です。設定してください")
      setButtonText("ホーム");
      return;
    }

    setGoalPower(newSelectedGoalPower);
    
    const newSelectedCoefficient = meter_list[currentIndex].COEFFICIENT ? meter_list[currentIndex].COEFFICIENT : null;
    setCoefficient(newSelectedCoefficient);

    const newSelectedCoefficientMagnification = meter_list[currentIndex].COEFFICIENT_MAGNIFICATION ? meter_list[currentIndex].COEFFICIENT_MAGNIFICATION : null;
    setCoefficientMagnification(newSelectedCoefficientMagnification);

    const newSelectedUnitOfDemandPower = meter_list[currentIndex].UNIT_OF_DEMAND_POWER;
    const unitOfDemandPower = newSelectedUnitOfDemandPower ? parseFloat(newSelectedUnitOfDemandPower.match(/[\d.]+/)) : null;
    setUnitOfDemandPower(unitOfDemandPower);

    //handleChangeStatus(meter_list[currentIndex].STATUS);

    setSelectedMeterId(newSelectedMeterId);
    onMeterSelect(newSelectedMeterId);
  };

  // Unicode エスケープをデコードするカスタム関数
  function unicodeDecode(str) {
    return str.replace(/\\u([\d\w]{4})/gi, (match, grp) => {
      return String.fromCharCode(parseInt(grp, 16));
    });
  }

  // Chart.js インスタンスの作成と更新
  const createOrUpdateChart = () => {
    if (chartRef.current) {
      // インスタンスが既に存在する場合は更新
      chartRef.current.data.datasets[0].data = chartData.contractPowerData;
      chartRef.current.data.datasets[1].data = chartData.goalPowerData;
      chartRef.current.data.datasets[2].data = chartData.nowGoalPowerData;
      chartRef.current.data.datasets[3].data = chartData.nowPowerData;
      
      chartRef.current.update();

      // Chart.jsのインスタンスの情報をコンソールに表示
    } 
  };

  const resetChartData = async () => {
    // グラフ破棄
    // データの初期化
    setChartData({
      contractPowerData:[{x: null, y: null}],
      goalPowerData:[{x: null, y: null}],
      nowPowerData: [{ x: null, y: null }],
      nowGoalPowerData: [{ x: null, y: null }],
    });
    setNowPower(null);
    setNowGoalPower(null);
    setGetDate(null);
    setGetTime(null);
    setGetTimeLimit(null);
    setRestTime(null);
    setCoefficient(0);
    setCoefficientMagnification(0);
    setUnitOfDemandPower(0);
    setMonthMaxPower(null);
  };

  const resetChartData_non_update = async () => {
    // グラフ破棄
    // データの初期化
    setChartData((prevData) => {
      return{
        ...prevData,
        nowPowerData: [{ x: 0, y: 0 }],
      }
      
    });
    setNowPower(null);
    setNowGoalPower(null);
    setGetDate(null);
    setGetTime(null);
    setGetTimeLimit(null);
    setRestTime(null);
    setCoefficient(0);
    setCoefficientMagnification(0);
    setUnitOfDemandPower(0);
    setMonthMaxPower(null);
  };

  useEffect(() => {
  
    // resetChartDataを呼び出す
    resetChartData();

    setFixedGraph();

    // 新しいメーターのデータを取得して更新
    fetchDataAndUpdateChart(selectedMeterId,true);

    // DynamoDBからの更新を検知してAPIからデータを取得する処理
    const intervalId = setInterval(() => {
      fetchDataAndUpdateChart(selectedMeterId,false);
    }, 10000);

    // コンポーネントがアンマウントされたときに実行されるクリーンアップ関数
    return () => {
      clearInterval(intervalId);
    };
  }, [selectedMeterId]);

  // Chart.js インスタンスの作成と更新を呼び出す
  useEffect(() => {
    createOrUpdateChart();
  }, [chartData]);

  const setFixedGraph = () => {
    if(memoizedGoalPower){
      setChartData({
        contractPowerData: [
          { x: 0, y: memoizedContractPower },
          { x: 3, y: memoizedContractPower },
          { x: 6, y: memoizedContractPower },
          { x: 9, y: memoizedContractPower },
          { x: 12, y: memoizedContractPower },
          { x: 15, y: memoizedContractPower },
          { x: 18, y: memoizedContractPower },
          { x: 21, y: memoizedContractPower },
          { x: 24, y: memoizedContractPower },
          { x: 27, y: memoizedContractPower },
          { x: 30, y: memoizedContractPower },
        ],
        goalPowerData: [
          { x: 0, y: memoizedGoalPower },
          { x: 3, y: memoizedGoalPower },
          { x: 6, y: memoizedGoalPower },
          { x: 9, y: memoizedGoalPower },
          { x: 12, y: memoizedGoalPower },
          { x: 15, y: memoizedGoalPower },
          { x: 18, y: memoizedGoalPower },
          { x: 21, y: memoizedGoalPower },
          { x: 24, y: memoizedGoalPower },
          { x: 27, y: memoizedGoalPower },
          { x: 30, y: memoizedGoalPower },
        ],
        nowGoalPowerData: [
          { x: 0, y: (memoizedGoalPower - 0) / 1800 * 0 + 0  },
          { x: 3, y: (memoizedGoalPower - 0) / 1800 * 180 + 0 },
          { x: 6, y: (memoizedGoalPower - 0) / 1800 * 360 + 0 },
          { x: 9, y: (memoizedGoalPower - 0) / 1800 * 540 + 0 },
          { x: 12, y: (memoizedGoalPower - 0) / 1800 * 720 + 0 },
          { x: 15, y: (memoizedGoalPower - 0) / 1800 * 900 + 0 },
          { x: 18, y: (memoizedGoalPower - 0) / 1800 * 1080 + 0 },
          { x: 21, y: (memoizedGoalPower - 0) / 1800 * 1260 + 0 },
          { x: 24, y: (memoizedGoalPower - 0) / 1800 * 1440 + 0 },
          { x: 27, y: (memoizedGoalPower - 0) / 1800 * 1620 + 0 },
          { x: 30, y: (memoizedGoalPower - 0) / 1800 * 1800 + 0 },
        ],
      });
    }
    
  }

  // 無通信の場合の処理を行う関数
  const handleNoCommunication = (aws_get_dt) => {
    const awsGetDate = new Date(aws_get_dt);
    // 現在の日本時間の Date オブジェクトを作成
    const nowInJapan = new Date(new Date().toLocaleString("ja-JP", {timeZone: "Asia/Tokyo"}));

    // 30分前の時刻を計算
    const thirtyMinutesAgo = new Date(nowInJapan);
    thirtyMinutesAgo.setMinutes(nowInJapan.getMinutes() - 30);

    if (awsGetDate <= thirtyMinutesAgo) {
      return true;
    }else{
      return false;
    }
  };

  // リアルタイムデータを更新する関数
  const fetchDataAndUpdateChart = async (meter_id,first_flg) => {
    if(!meter_id){
      return;
    }
    try {
      const requestData = {
        path: '/get_monitor_data',
        body: JSON.stringify({
          meter_id: meter_id,
        }),
      };

      const response = await axios.post(apiEndpoint, requestData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // response が undefined でないかチェック
      if (response && response.data) {
        // レスポンスのステータスコードに応じて処理
        if (response.data.statusCode === 200) {
          const cleanedBody = response.data.body.replace(/\\/g, '');
          const decodedBody = unicodeDecode(cleanedBody);
          const parsedBody = JSON.parse(decodedBody);

          const now_power = parsedBody.NOW_POWER !== -1 ? parsedBody.NOW_POWER : null;
          const goal_now_power = parsedBody.GOAL_NOW_POWER !== -1 ? parsedBody.GOAL_NOW_POWER : null;
          const progress_time = parsedBody.PROGRESS_TIME;
          let progress_time_num = parseFloat(progress_time);
          const reset_flg = parsedBody.RESET_FLG;
          const reset_flg_num = parseFloat(reset_flg);
          const aws_get_dt = parsedBody.AWS_GET_DT;
          const get_date = parsedBody.GET_DATE;
          const get_time = parsedBody.GET_TIME;
          const monthly_maximum_demand_power = parsedBody.MONTHLY_MAXIMUM_DEMAND_POWER !== -1 ? parsedBody.MONTHLY_MAXIMUM_DEMAND_POWER : null;
          //最新データを使って計算
          let monthly_maximum_demand_power_result
          if(monthly_maximum_demand_power !== null && coefficient !== null && coefficient_magnification !== null && unit_of_demand_power !== null){
            monthly_maximum_demand_power_result = Math.floor(monthly_maximum_demand_power * coefficient * coefficient_magnification * unit_of_demand_power);
          }
          
          let get_time_converted;
          let get_time_rest;

          if(aws_get_dt === prev_aws_get_dt){
            if(!non_communication_flg && handleNoCommunication(aws_get_dt)){
              alert("最後の通信が発生してから30分以上経過しています");
              non_communication_flg = true;
              resetChartData_non_update();
              setIcon(<span style={{ marginBottom: '0.5em' }}>&nbsp;</span>);
            }
            return;
          }

          // 無通信の場合
          if (handleNoCommunication(aws_get_dt)) {
              // ポップアップを表示する処理をここに追加
              alert("最後の通信が発生してから30分以上経過しています");
              non_communication_flg = true;
              prev_aws_get_dt = aws_get_dt;
              resetChartData_non_update();
              setIcon(<span style={{ marginBottom: '0.5em' }}>&nbsp;</span>);
              return;
          }

          non_communication_flg = false;

          if(progress_time_num == 0){
            progress_time_num = 1800
          }

          
          if(get_time){
            get_time_converted = convertTimeTo30Minutes(get_time);
            get_time_rest = convertTimeToRestMinutes(get_time);
          }

          handleChangeStatus(parsedBody.STATUS);

          // アンマウント時に Chart.js インスタンスを破棄
          if (reset_flg_num === 1) {
            if(chartRef.current){

              setSelectedMeterId(meter_id);

              setChartData((prevData) => {
                return{
                  ...prevData,
                  nowPowerData: [{ x: 0, y: 0 }],
                }
                
              });
              if(now_power !== null){
                setChartData((prevData) => {
                
                  return{
                  ...prevData,
                  nowPowerData: [...prevData.nowPowerData,{ x: progress_time_num/60, y: now_power }],
                  }
                
                
                });
              }
              
              prev_aws_get_dt = aws_get_dt;
                
            }
                        
          }else{
            if(first_flg){

              setChartData((prevData) => {
                return{
                  ...prevData,
                  nowPowerData: [{ x: 0, y: 0 }],
                }
                
              });

              // 最新時限の過去分を取得
              const requestData = {
                path: '/get_monitor_history_data',
                body: JSON.stringify({
                  meter_id: meter_id,
                  get_date:get_date,
                  get_hour:get_time_converted.split(':')[0],
                  get_min:get_time_converted.split(':')[1],
                  searcr_flg:'0',
                }),
              };
        
              const response = await axios.post(apiEndpoint, requestData, {
                headers: {
                  'Content-Type': 'application/json',
                },
              });
              console.log('過去分レスポンス:',response);
              // response が undefined でないかチェック
              if (response && response.data) {
                // レスポンスのステータスコードに応じて処理
                if (response.data.statusCode === 200) {
                  const cleanedBody = response.data.body.replace(/\\/g, '');
                  const decodedBody = unicodeDecode(cleanedBody);
                  const parsedBody = JSON.parse(decodedBody);
        
                  const monitor_history_list = parsedBody.monitor_history_data;
                  for (const monitor_history of monitor_history_list){
                    const now_power = monitor_history.NOW_POWER !== -1 ? monitor_history.NOW_POWER: null;
                    const progress_time = monitor_history.PROGRESS_TIME;
                    let progress_time_num = parseFloat(progress_time);
        
                    if (progress_time_num === 0){
                      progress_time_num = 1800;
                    }
        
                    if(now_power !== null){
                      setChartData((prevData) => ({
                        ...prevData,
                        nowPowerData: [...prevData.nowPowerData, { x: progress_time_num/60, y: now_power }],
                      }));
                    }
                    
                  }
                }
              }
            }else{
              if(now_power !== null){
                setChartData((prevData) => {
            
                  return {
                    ...prevData,
                    nowPowerData: prevData.nowPowerData ? [...prevData.nowPowerData, { x: progress_time_num/60, y: now_power }] : [{ x: progress_time_num/60, y: now_power }],
                  };
                });

              }
              
            }
            
            
          }
          
          setNowPower(now_power);
          setNowGoalPower(goal_now_power);
          setMonthMaxPower(monthly_maximum_demand_power_result);
          setGetDate(get_date);
          setGetTime(get_time);
          setGetTimeLimit(get_time_converted);
          setRestTime(get_time_rest);
          
          prev_aws_get_dt = aws_get_dt;
                            
        }else{
          const body = JSON.parse(response.data.body);
          console.log("エラーが発生しました。 エラー内容:" + body.message);
        }
      } else {
        console.error('Response is undefined or does not have data property');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // METER_LISTセレクトボックス変更時の処理を追加
  useEffect(() => {

    //メーターリスト構築
    fetchData();

  }, []);

  // useEffectフックを使ってrest_timeをカウントダウンする
  useEffect(() => {
    let intervalId;

    const countdown = () => {
      intervalId = setInterval(() => {
        // rest_timeを1秒ずつ減らす
        setRestTime((prevRestTime) => {
          if(prevRestTime){
            const timeParts = prevRestTime.split(':');
            let minutes = parseInt(timeParts[0]);
            let seconds = parseInt(timeParts[1]);

            if (minutes === 0 && seconds === 0) {
              clearInterval(intervalId); // タイマーが0になったらタイマーを停止する
            } else if (seconds === 0) {
              minutes -= 1;
              seconds = 59;
            } else {
              seconds -= 1;
            }

            // 残り時間の形式に整形して返す
            return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
          }
          
        });
      }, 1000);
    };

    countdown();

    // コンポーネントがアンマウントされたときにクリーンアップする
    return () => clearInterval(intervalId);
  }, [rest_time]);

  const fetchData = async () => {
    try {
      setIsLoading(true); // 読み込み中の状態を true に設定
      const data = await fetchDataFromServer(company_id,privilegeFlg);
      if (data) {
        const dataArray = JSON.parse(data);
        setMeterList(dataArray);
        if(meter_id){
          const currentIndex = dataArray.findIndex((meter) => meter.METER_ID === meter_id);

          const newSelectedContractPower = dataArray[currentIndex].CONTRACT_POWER;
          if(!newSelectedContractPower){
            alert("契約電力が未設定です。設定してください")
            setButtonText("ホーム");
            return;
          }
          setContractPower(newSelectedContractPower);
          
          const newSelectedGoalPower = dataArray[currentIndex].GOAL_POWER;
          if(!newSelectedGoalPower){
            alert("目標電力が未設定です。設定してください")
            setButtonText("ホーム");
            return;
          }

          setGoalPower(newSelectedGoalPower);

          const newSelectedCoefficient = dataArray[currentIndex].COEFFICIENT ? dataArray[currentIndex].COEFFICIENT : null;
          setCoefficient(newSelectedCoefficient);

          const newSelectedCoefficientMagnification = dataArray[currentIndex].COEFFICIENT_MAGNIFICATION ? dataArray[currentIndex].COEFFICIENT_MAGNIFICATION : null;
          setCoefficientMagnification(newSelectedCoefficientMagnification);

          const newSelectedUnitOfDemandPower = dataArray[currentIndex].UNIT_OF_DEMAND_POWER;
          const unitOfDemandPower = newSelectedUnitOfDemandPower ? parseFloat(newSelectedUnitOfDemandPower.match(/[\d.]+/)) : null;
          setUnitOfDemandPower(unitOfDemandPower);

          setSelectedMeterId(meter_id);
          onMeterSelect(meter_id);
        }else{

          const newSelectedContractPower = dataArray[0].CONTRACT_POWER;
          if(!newSelectedContractPower){
            alert("契約電力が未設定です。設定してください")
            setButtonText("ホーム");
            return;
          }
          setContractPower(newSelectedContractPower);

          const newSelectedGoalPower = dataArray[0].GOAL_POWER;
          if(!newSelectedGoalPower){
            alert("目標電力が未設定です。設定してください")
            setButtonText("ホーム");
            return;
          }

          setGoalPower(newSelectedGoalPower);

          const newSelectedCoefficient = dataArray[0].COEFFICIENT ? dataArray[0].COEFFICIENT : null;
          setCoefficient(newSelectedCoefficient);

          const newSelectedCoefficientMagnification = dataArray[0].COEFFICIENT_MAGNIFICATION ? dataArray[0].COEFFICIENT_MAGNIFICATION : null;
          setCoefficientMagnification(newSelectedCoefficientMagnification);

          const newSelectedUnitOfDemandPower = dataArray[0].UNIT_OF_DEMAND_POWER;
          const unitOfDemandPower = newSelectedUnitOfDemandPower ? parseFloat(newSelectedUnitOfDemandPower.match(/[\d.]+/)) : null;
          setUnitOfDemandPower(unitOfDemandPower);

          setSelectedMeterId(dataArray[0].METER_ID);
          onMeterSelect(dataArray[0].METER_ID);
        }
        
      }
    } catch (error) {
      console.error('Error fetching data from server:', error);
    }
    setIsLoading(false); // 読み込み中の状態を true に設定
  };

  const data = {
    labels:  ["0分", "3分", "6分", "9分", "12分" ,"15分","18分","21分","24分","27分","30分"],
    datasets: [
      {
        label: '契約電力(kW)',
        data: chartData.contractPowerData,
        borderColor: 'black',
        borderWidth: 2,
        pointStyle: 'circle',
      },
      {
        label: '目標電力(kW)',
        data: chartData.goalPowerData,
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 2,
        pointStyle: 'circle',
      },
      {
        label: '現在目標電力(kW)',
        data: chartData.nowGoalPowerData,
        borderColor: 'rgba(255, 255, 0, 0.5)', // 薄い黄色
        borderWidth: 2,
        pointStyle: 'circle',
      },
      {
        label: '現在電力(kW)',
        data: chartData.nowPowerData,
        borderColor: 'rgba(0, 123, 255, 1)', // 濃い青色
        borderWidth: 2,
        pointStyle: 'circle',
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        min: 0,
        max: 30,
        ticks: {
          stepSize: 3,
          callback: function(value) {
            return value + '分';
          }
        }
      },
      y: {
        min: 0,
        max: parseFloat(contract_power) + (parseFloat(contract_power) * 0.1),
        ticks: {
          callback: function(value) {
            return value + 'kW';
          }
        },
        beginAtZero: true,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            // y軸の値を取得し、小数点以下を四捨五入する
            const roundedValue = tooltipItem.parsed.y.toFixed(1);
            // 小数点以下が0の場合は整数にする
            const displayValue = roundedValue.endsWith('.0') ? parseInt(roundedValue, 10) : roundedValue;
            return `${tooltipItem.dataset.label}: ${displayValue} kW`;
          },
          title: function() {
            // タイトル（x軸の値）を空にする
            return '';
          }
        }
      }
    }
  };

  // 各変数の桁数を計算して最大の桁数を求める
  const maxDigits = Math.max(
    memoizedNowGoalPower !== undefined && memoizedNowGoalPower !== null ? memoizedNowGoalPower.toString().length : 0,
    memoizedGoalPower !== undefined && memoizedGoalPower !== null ? memoizedGoalPower.toString().length: 0,
    memoizedContractPower !== undefined && memoizedContractPower !== null ? memoizedContractPower.toString().length: 0,
    memoizedMonthMaxPower !== undefined && memoizedMonthMaxPower !== null ? memoizedMonthMaxPower.toString().length:0
  );

  return (
    <React.Fragment>
      {isLoading ? ( // 読み込み中の場合、ローディング画面を表示
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}>
          <CircularProgress /> Loading...
        </div>
        ) : (
        <div style={{ display: 'flex' }}>
          {/* 左側のデマンド情報 */}
          <div style={{ flex: '0 0 25%' }}>
            <Title>リアルタイム監視</Title>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px', marginTop: '16px' }}>
              <div id="demond_switch">
                <Button
                  id="prevButton" 
                  variant="contained" 
                  color="primary" 
                  onClick={handlePrevClick} 
                  style={{ marginRight: '8px' }}
                  disabled={meter_list.findIndex((meter) => meter.METER_ID === memoizedSelectedMeterId) === 0}
                >
                  <SkipPreviousIcon />
                  前へ
                </Button>
                <Button
                  id="nextButton"
                  variant="contained"
                  color="primary"
                  onClick={handleNextClick}
                  disabled={meter_list.findIndex((meter) => meter.METER_ID === memoizedSelectedMeterId) === meter_list.length - 1}
                >
                  <SkipNextIcon />
                  次へ
                </Button>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center',width:'100%' }}>
              <div id="demond_data" style={{ width:'100%' }}>
                <Typography component="p" variant="h6">
                  計器ID:
                  <select style={inputStyle} id='METER_LIST' onChange={handleChangeMeterId}>
                    {meter_list.map((meter) => {
                      return <option key={meter.METER_ID} selected={meter.METER_ID === memoizedSelectedMeterId}>{meter.SERIAL_NO}</option>;
                    })}
                  </select>
                </Typography>
                <Typography component="p" variant="h6">
                  デマンド時限&emsp;：{memoizedGetDate} {memoizedGetTimeLimit}
                </Typography>
                <Typography component="p" variant="h6">
                  データ取得時刻：{memoizedGetDate} {memoizedGetTime}
                </Typography>
                <Typography component="p" variant="h6">
                  残り時間：{rest_time}
                </Typography>
                <div id="demond_icon">
                  {icon}
                </div>
                <Typography component="p" variant="h6" sx={{ fontSize: '2.0rem', fontWeight: 'bold'}}>
                  現在電力：{memoizedNowPower}kW
                </Typography>
                <Typography component="p" variant="h6">
                  現在目標電力：{memoizedNowGoalPower !== undefined && memoizedNowGoalPower !== null ? addSpaces(memoizedNowGoalPower.toString().length, maxDigits) : ''}{memoizedNowGoalPower}kW
                </Typography>
                <Typography component="p" variant="h6">
                  目標電力&emsp;&emsp;：{memoizedGoalPower !== undefined && memoizedGoalPower !== null ? addSpaces(memoizedGoalPower.toString().length, maxDigits) : ''}{memoizedGoalPower}kW
                </Typography>
                <Typography component="p" variant="h6">
                  契約電力&emsp;&emsp;：{memoizedContractPower !== undefined && memoizedContractPower !== null ? addSpaces(memoizedContractPower.toString().length, maxDigits) : ''}{memoizedContractPower}kW
                </Typography>
                <Typography component="p" variant="h6">
                  月間最大電力：{memoizedMonthMaxPower !== undefined && memoizedMonthMaxPower !== null ? addSpaces(memoizedMonthMaxPower.toString().length, maxDigits) : ''}{memoizedMonthMaxPower}kW
                </Typography>
                
              </div>
            </div>
          </div>

          {/* 右側のチャート */}
          <div style={{ width: '75%',height: '550px'}}>
            <Line data={data} options={options} ref={(ref) => chartRef.current = ref} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

Chart.propTypes = {
  meter_id: PropTypes.string.isRequired,
  company_id: PropTypes.string.isRequired,
  privilegeFlg: PropTypes.string.isRequired,
  onMeterSelect: PropTypes.func,
  setButtonText: PropTypes.func,
};