import React, { useState, useEffect ,useRef ,useMemo } from 'react';
import { Line } from 'react-chartjs-2'; // react-chartjs-2からLineをimport
import PropTypes from 'prop-types'; // 追加
import axios from "axios";
import Title from './Title';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import Button from '@mui/material/Button';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

// 必要な数の半角スペースを追加する関数
function addSpaces(currentLength, maxLength) {
  let spacesNeeded = parseInt(maxLength) - parseInt(currentLength);
  if(spacesNeeded > 0){
    spacesNeeded = spacesNeeded + spacesNeeded;
  }
  return '\u00A0'.repeat(spacesNeeded);
}

const fetchDataFromServer = async (company_id,privilegeFlg) => {
  try {
    
    const info_flg = '0';
    if(!privilegeFlg){
      privilegeFlg = '0';
    }

    const requestData = {
      path: '/get_info',
      body: JSON.stringify({
        company_id: company_id,
        info_flg: info_flg,
        privilege_flg:privilegeFlg,
        chart_flg:'1',
      }),
    };

    const response = await axios.post(apiEndpoint, requestData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response && response.data && response.data.statusCode === 200) {
      return response.data.body; // レスポンスのデータを返す
    } else {
      alert('Error in API response:', response.data);
      return null;
    }
  } catch (error) {
    console.error('Error fetching data from server:', error);
    return null;
  }
};

export default function ChartHistory({meter_id,company_id,privilegeFlg,onMeterSelect}) {
  const [chartData, setChartData] = useState({
    nowPowerData: [],
    nowGoalPowerData: [],
    goalPowerData: [],
    contractPowerData: [],
  });
  const chartRef = useRef(null);
  const [meter_list, setMeterList] = React.useState([]);
  const [selectedMeterId, setSelectedMeterId] = React.useState(null);
  const [cycle_demand_power, setCycleDemandPower] = React.useState(null);
  const [goal_power, setGoalPower] = React.useState(null);
  const [contract_power,setContractPower]= React.useState(null);
  const [month_max_power,setMonthMaxPower]= React.useState(null);
  const buttonProcessing = useRef(false);
  const memoizedSelectedMeterId = useMemo(() => selectedMeterId, [selectedMeterId]);
  const memoizedCycleDemandPower = useMemo(() => cycle_demand_power, [cycle_demand_power]);
  const memoizedGoalPower = useMemo(() => goal_power, [goal_power]);
  const memoizedContractPower = useMemo(() => contract_power, [contract_power]);
  const memoizedMonthMaxPower = useMemo(() => month_max_power, [month_max_power]);
  const [showData, setShowData] = useState(false); // テーブルの表示非表示

  const currentDate = new Date();
  const minDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate());
  const maxDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()+1);
  const [selectedDate, setSelectedDate] = useState('');
  
  const [icon, setIcon] = useState(<TagFacesIcon sx={{ fontSize: '90px' }} />);
  const [isLoading, setIsLoading] = useState(true); // 読み込み中の状態を管理

  const inputStyle = {
    height: '40px',
    width: '60%',
    fontSize: '20px',
    marginBottom:20,
  };

  const isValidDate = (inputDate) => {
    const dateParts = inputDate.split('-');
    const inputDateObj = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
    return inputDateObj >= minDate && inputDateObj <= maxDate;
  };

  const handleDateChange = (e) => {
    resetChartData();
    const selectedDate = e.target.value;
    setSelectedDate(selectedDate);
  };

  const handleInputBlur = () => {
    if (selectedDate && !isValidDate(selectedDate)) {
      setSelectedDate('');
      alert('1年以上前の日付または未来の日付を選択することはできません。');
    }
  };

  // handleChangeStatus関数の定義
  const handleChangeStatus = async (cycle_demand_power, goal_power) => {
    let newIcon = <TagFacesIcon sx={{ fontSize: '90px' }} />;
    if (cycle_demand_power > goal_power) {
      newIcon = <MoodBadIcon sx={{ fontSize: '90px', color: 'red' }} />;
    }
    setIcon(newIcon);
  };

  const handlePrevClick = async () => {
    console.log(buttonProcessing.current);
    if (buttonProcessing.current) return; // 送信処理中の場合は処理を中断する
    buttonProcessing.current = true; // 送信処理中フラグを立てる
    
    const currentIndex = meter_list.findIndex((meter) => meter.METER_ID === selectedMeterId);
  
    // 現在の選択が最初の選択でない場合
    if (currentIndex > 0) {
      const newSelectedMeterId = meter_list[currentIndex - 1].METER_ID;

      setSelectedMeterId(newSelectedMeterId);
      onMeterSelect(newSelectedMeterId);
      
    }
    setCycleDemandPower(null);
    setGoalPower(null);
    setContractPower(null);
    setMonthMaxPower(null);

    setShowData(false);
    
    buttonProcessing.current = false;
    
  };

  const handleNextClick = async () => {
    console.log(buttonProcessing.current);
    if (buttonProcessing.current) return; // 送信処理中の場合は処理を中断する
    buttonProcessing.current = true; // 送信処理中フラグを立てる

    const currentIndex = meter_list.findIndex((meter) => meter.METER_ID === selectedMeterId);
  
    // 現在の選択が最初の選択でない場合
    if (currentIndex < meter_list.length - 1) {
      const newSelectedMeterId = meter_list[currentIndex + 1].METER_ID;

      setSelectedMeterId(newSelectedMeterId);
      onMeterSelect(newSelectedMeterId);
    }

    setCycleDemandPower(null);
    setGoalPower(null);
    setContractPower(null);
    setMonthMaxPower(null);

    setShowData(false);
    buttonProcessing.current = false;
  
  };

  const handleChangeMeterId = (event) => { 
    
    const newSelectedMeterId = meter_list[event.target.selectedIndex].METER_ID;
    setSelectedMeterId(newSelectedMeterId);
    onMeterSelect(newSelectedMeterId);

    setCycleDemandPower(null);
    setGoalPower(null);
    setContractPower(null);
    setMonthMaxPower(null);

    setShowData(false);
  };

  const handleChangeDt = () => { 

    resetChartData();

    setShowData(false);
  };

  const handleSearch = async () => {
    const get_date = document.getElementById('GET_DATE').value;
    if(get_date === ''){
      alert("日付を入力してください。");
      return;
    }
    let get_hour = document.getElementById('GET_HOUR').value;
    if(get_hour === ''){
      alert("時間を選択してください。");
      return;
    }
    const get_min = document.getElementById('GET_MIN').value;
    if(get_min === ''){
      alert("分を選択してください。");
      return;
    }

    // 時間を2桁の文字列に変換（先頭に0を埋める）
    get_hour = get_hour.padStart(2, '0');

    // fetchData を呼び出す
    await fetchDataAndUpdateChart(selectedMeterId,get_date,get_hour,get_min);
  };

  // Unicode エスケープをデコードするカスタム関数
  function unicodeDecode(str) {
    return str.replace(/\\u([\d\w]{4})/gi, (match, grp) => {
      return String.fromCharCode(parseInt(grp, 16));
    });
  }

  // Chart.js インスタンスの作成と更新
  const createOrUpdateChart = () => {
    if (chartRef.current) {
      // インスタンスが既に存在する場合は更新
      chartRef.current.data.datasets[0].data = chartData.contractPowerData;
      chartRef.current.data.datasets[1].data = chartData.goalPowerData;
      chartRef.current.data.datasets[2].data = chartData.nowGoalPowerData;
      chartRef.current.data.datasets[3].data = chartData.nowPowerData;
      
      chartRef.current.update();

      // Chart.jsのインスタンスの情報をコンソールに表示
    } 
  };

  // selectedMeterIdが変更されたときの処理
  const resetChartData = async () => {
    // グラフ破棄
    // データの初期化
    setChartData({
      nowPowerData: [{ x: null, y: null }],
      nowGoalPowerData: [{ x: null, y: null }],
    });
    setCycleDemandPower(null);
    setGoalPower(null);
    setContractPower(null);
    setMonthMaxPower(null);
  };

  useEffect(() => {
    // resetChartDataを呼び出す
    resetChartData();
  }, [selectedMeterId]);

  // Chart.js インスタンスの作成と更新を呼び出す
  useEffect(() => {
    createOrUpdateChart();
  }, [chartData]);

  useEffect(() => {
    if (goal_power !== null) {
      // goal_powerが取得されたら、goalPowerDataにセットする
      setChartData(prevData => ({
        ...prevData,
        contractPowerData: [
          { x: 0, y: contract_power },
          { x: 3, y: contract_power },
          { x: 6, y: contract_power },
          { x: 9, y: contract_power },
          { x: 12, y: contract_power },
          { x: 15, y: contract_power },
          { x: 18, y: contract_power },
          { x: 21, y: contract_power },
          { x: 24, y: contract_power },
          { x: 27, y: contract_power },
          { x: 30, y: contract_power },
        ],
        goalPowerData: [
          { x: 0, y: goal_power },
          { x: 3, y: goal_power },
          { x: 6, y: goal_power },
          { x: 9, y: goal_power },
          { x: 12, y: goal_power },
          { x: 15, y: goal_power },
          { x: 18, y: goal_power },
          { x: 21, y: goal_power },
          { x: 24, y: goal_power },
          { x: 27, y: goal_power },
          { x: 30, y: goal_power },
        ],
        nowGoalPowerData: [
          { x: 0, y: (goal_power - 0) / 1800 * 0 + 0  },
          { x: 3, y: (goal_power - 0) / 1800 * 180 + 0 },
          { x: 6, y: (goal_power - 0) / 1800 * 360 + 0 },
          { x: 9, y: (goal_power - 0) / 1800 * 540 + 0 },
          { x: 12, y: (goal_power - 0) / 1800 * 720 + 0 },
          { x: 15, y: (goal_power - 0) / 1800 * 900 + 0 },
          { x: 18, y: (goal_power - 0) / 1800 * 1080 + 0 },
          { x: 21, y: (goal_power - 0) / 1800 * 1260 + 0 },
          { x: 24, y: (goal_power - 0) / 1800 * 1440 + 0 },
          { x: 27, y: (goal_power - 0) / 1800 * 1620 + 0 },
          { x: 30, y: (goal_power - 0) / 1800 * 1800 + 0 },
        ],
      }));
    }
  }, [goal_power]);

  // グラフデータを更新する関数
  const fetchDataAndUpdateChart = async (meter_id,get_date,get_hour,get_min) => {
    
    try {
      const requestData = {
        path: '/get_monitor_history_data',
        body: JSON.stringify({
          meter_id: meter_id,
          get_date:get_date,
          get_hour:get_hour,
          get_min:get_min,
          searcr_flg:'1',
        }),
      };

      const response = await axios.post(apiEndpoint, requestData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // response が undefined でないかチェック
      if (response && response.data) {
        // レスポンスのステータスコードに応じて処理
        if (response.data.statusCode === 200) {
          resetChartData();
          
          const cleanedBody = response.data.body.replace(/\\/g, '');
          const decodedBody = unicodeDecode(cleanedBody);
          const parsedBody = JSON.parse(decodedBody);

          const monitor_history_list = parsedBody.monitor_history_data;

          setChartData((prevData) => {
            return{
              ...prevData,
              nowPowerData: [{ x: 0, y: 0 }],
            }
            
          });
          for (const monitor_history of monitor_history_list){
            const now_power = monitor_history.NOW_POWER !== -1 ? monitor_history.NOW_POWER: null;
            const progress_time = monitor_history.PROGRESS_TIME;
            let progress_time_num = parseFloat(progress_time);

            if (progress_time_num === 0){
              progress_time_num = 1800;
            }
            if(now_power !== null){
              setChartData((prevData) => ({
                nowPowerData: [...prevData.nowPowerData, { x: progress_time_num/60, y: now_power }],
              }));
            }
          }

          //定時需要電力
          const history_data = parsedBody.history_data;
          const cycle_demand_power = history_data.CYCLE_DEMAND_POWER !== -1 ? history_data.CYCLE_DEMAND_POWER : null;

          const latest_data = parsedBody.latest_data;
          
          //係数
          const coefficient = latest_data.COEFFICIENT;
          //係数の倍率
          const coefficient_magnification = latest_data.COEFFICIENT_MAGNIFICATION;
          //需要電力単位
          const unit_of_demand_power = latest_data.UNIT_OF_DEMAND_POWER;
          // unit_of_demand_power から数値のみを抜き出す
          const unitOfDemandPower = unit_of_demand_power ? parseFloat(unit_of_demand_power.match(/[\d.]+/)) : null;

          //最新データを使って計算
          let cycle_demand_power_result;
          if(cycle_demand_power !== null && coefficient !== null && coefficient_magnification !== null && unitOfDemandPower !== null){
            cycle_demand_power_result = Math.floor(cycle_demand_power * coefficient * coefficient_magnification * unitOfDemandPower);
          }
          setCycleDemandPower(cycle_demand_power_result);

          //月間最大需要電力
          const monthly_maximum_demand_power = history_data.MONTHLY_MAXIMUM_DEMAND_POWER !== -1 ? history_data.MONTHLY_MAXIMUM_DEMAND_POWER:null;

          //最新データを使って計算
          let monthly_maximum_demand_power_result;
          if(monthly_maximum_demand_power !== null && coefficient !== null && coefficient_magnification !== null && unitOfDemandPower !== null){
            monthly_maximum_demand_power_result = Math.floor(monthly_maximum_demand_power * coefficient * coefficient_magnification * unitOfDemandPower);
          }
          setMonthMaxPower(monthly_maximum_demand_power_result);

          //契約電力
          const contract_power = monitor_history_list[0].CONTRACT_POWER;
          setContractPower(contract_power);

          //目標電力
          const goal_power = monitor_history_list[0].GOAL_POWER;
          setGoalPower(goal_power);

          handleChangeStatus(cycle_demand_power_result,goal_power);

          setShowData(true);
                            
        }else{
          const cleanedBody = response.data.body.replace(/\\/g, '');
          const decodedBody = unicodeDecode(cleanedBody);
          const parsedBody = JSON.parse(decodedBody);
          alert(parsedBody.message);
          return;
        }
      } else {
        console.error('Response is undefined or does not have data property');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // METER_LISTセレクトボックス変更時の処理を追加
  useEffect(() => {

    //メーターリスト構築
    fetchData();

  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true); // 読み込み中の状態を true に設定
      const data = await fetchDataFromServer(company_id,privilegeFlg);
      if (data) {
        const dataArray = JSON.parse(data);
        setMeterList(dataArray);
        if(meter_id){
          setSelectedMeterId(meter_id);
          onMeterSelect(meter_id);

        }else{

          setSelectedMeterId(dataArray[0].METER_ID);
          onMeterSelect(dataArray[0].METER_ID);
        }
        
      }
    } catch (error) {
      console.error('Error fetching data from server:', error);
    }
    setIsLoading(false); // 読み込み中の状態を true に設定
  };

  const data = {
    labels:  ["0分", "3分", "6分", "9分", "12分" ,"15分","18分","21分","24分","27分","30分"],
    datasets: [
      {
        label: '契約電力(kW)',
        data: chartData.contractPowerData,
        borderColor: 'black',
        borderWidth: 2,
        fill: false,
      },
      {
        label: '目標電力(kW)',
        data: chartData.goalPowerData,
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 2,
        fill: false,
      },
      {
        label: '現在目標電力(kW)',
        data: chartData.nowGoalPowerData,
        borderColor: 'rgba(255, 255, 0, 0.5)', // 薄い黄色
        borderWidth: 2,
        fill: false,
      },
      {
        label: '現在電力(kW)',
        data: chartData.nowPowerData,
        borderColor: 'rgba(0, 123, 255, 1)', // 濃い青色
        borderWidth: 2,
        fill: false,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        min: 0,
        max: 30,
        ticks: {
          stepSize: 3,
          callback: function(value) {
            return value + '分';
          }
        }
      },
      y: {
        min: 0,
        max: parseFloat(contract_power) + (parseFloat(contract_power) * 0.1),
        ticks: {
          callback: function(value) {
            return value + 'kW';
          }
        },
        beginAtZero: true,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            // y軸の値を取得し、小数点以下を四捨五入する
            const roundedValue = tooltipItem.parsed.y.toFixed(1);
            // 小数点以下が0の場合は整数にする
            const displayValue = roundedValue.endsWith('.0') ? parseInt(roundedValue, 10) : roundedValue;
            return `${tooltipItem.dataset.label}: ${displayValue} kW`;
          },
          title: function() {
            // タイトル（x軸の値）を空にする
            return '';
          }
        }
      }
    }
  };

  // 各変数の桁数を計算して最大の桁数を求める
  const maxDigits = Math.max(
    memoizedGoalPower !== undefined && memoizedGoalPower !== null ? memoizedGoalPower.toString().length: 0,
    memoizedContractPower !== undefined && memoizedContractPower !== null ? memoizedContractPower.toString().length: 0,
    memoizedMonthMaxPower !== undefined && memoizedMonthMaxPower !== null ? memoizedMonthMaxPower.toString().length:0
  );

  return (
    <React.Fragment>
      {isLoading ? ( // 読み込み中の場合、ローディング画面を表示
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}>
          <CircularProgress /> Loading...
        </div>
        ) : (
          <div style={{ display: 'flex' }}>
            {/* 左側のデマンド情報 */}
            <div style={{ flex: '0 0 25%' }}>
              <Title>過去デマンド</Title>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px', marginTop: '16px' }}>
                <div id="demond_switch">
                  <Button
                    id="prevButton" 
                    variant="contained" 
                    color="primary" 
                    onClick={handlePrevClick} 
                    style={{ marginRight: '8px' }}
                    disabled={meter_list.findIndex((meter) => meter.METER_ID === memoizedSelectedMeterId) === 0}
                  >
                    <SkipPreviousIcon />
                    前へ
                  </Button>
                  <Button
                    id="nextButton"
                    variant="contained"
                    color="primary"
                    onClick={handleNextClick}
                    disabled={meter_list.findIndex((meter) => meter.METER_ID === memoizedSelectedMeterId) === meter_list.length - 1}
                  >
                    <SkipNextIcon />
                    次へ
                  </Button>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center',width:'100%' }}>
                <div id="demond_data" style={{ width:'100%' }}>
                  <Typography component="p" variant="h6">
                    計器ID:
                    <select style={inputStyle} id='METER_LIST' onChange={handleChangeMeterId}>
                      {meter_list.map((meter) => {
                        return <option key={meter.METER_ID} selected={meter.METER_ID === memoizedSelectedMeterId}>{meter.SERIAL_NO}</option>;
                      })}
                    </select>
                  </Typography>
                  <Typography component="p" variant="h6">
                    デマンド時限：
                    <input
                      id='GET_DATE'
                      type="date"
                      style={{ height: '35px', fontSize: '16px' ,width:'120px'}}
                      min={minDate.toISOString().split('T')[0]} // 1年以上前の日付
                      max={maxDate.toISOString().split('T')[0]} // 現在日付
                      onChange={handleDateChange}
                      onBlur={handleInputBlur}
                      value={selectedDate}
                    />
                    <select id='GET_HOUR' style={{ height: '35px', fontSize: '16px' ,width:'60px'}} onChange={handleChangeDt}>
                      <option value="">--</option>
                        {[...Array(24).keys()].map(hour => (
                          <option key={hour} value={hour}>{hour}</option>
                        ))}
                    </select>
                    <select id='GET_MIN' style={{ height: '35px', fontSize: '16px' ,width:'60px'}} onChange={handleChangeDt}>
                      <option value="">--</option>
                      <option value="00">00</option>
                      <option value="30">30</option>
                    </select>
                  </Typography>
                  <Typography component="p" variant="h6">
                  <Button 
                    variant="contained"
                    color="primary"
                    style={{marginTop:10,marginBottom:10,}}
                    onClick={() => {
                      handleSearch();
                    }}
                  >
                    検索<SearchIcon />
                  </Button>
                  </Typography>
                  {showData && (
                    <div id="demond_icon">
                      {icon}
                    </div>
                  )}
                  {showData && (
                    <div>
                      <Typography component="p" variant="h6" sx={{ fontSize: '2.0rem', fontWeight: 'bold'}}>
                        定時需要電力：{memoizedCycleDemandPower}kW
                      </Typography>
                      <Typography component="p" variant="h6">
                        目標電力&emsp;&emsp;：{memoizedGoalPower !== undefined && memoizedGoalPower !== null ? addSpaces(memoizedGoalPower.toString().length, maxDigits) : ''}{memoizedGoalPower}kW
                      </Typography>
                      <Typography component="p" variant="h6">
                        契約電力&emsp;&emsp;：{memoizedContractPower !== undefined && memoizedContractPower !== null ? addSpaces(memoizedContractPower.toString().length, maxDigits) : ''}{memoizedContractPower}kW
                      </Typography>
                      <Typography component="p" variant="h6">
                        当時月間最大：{memoizedMonthMaxPower !== undefined && memoizedMonthMaxPower !== null ? addSpaces(memoizedMonthMaxPower.toString().length, maxDigits) : ''}{memoizedMonthMaxPower}kW
                      </Typography>
                    </div>
                  )}
                  
                </div>
              </div>
            </div>
            {showData && (
              <div style={{ width: '75%',height: '550px'}}>
                <Line data={data} options={options} ref={(ref) => chartRef.current = ref} />
              </div>
            )}
          </div>
        )}
    </React.Fragment>
  );
}

ChartHistory.propTypes = {
  meter_id: PropTypes.string.isRequired,
  company_id: PropTypes.string.isRequired,
  privilegeFlg: PropTypes.string.isRequired,
  onMeterSelect: PropTypes.func,
};